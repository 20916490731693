.ProjectCards h2{
    margin: q0px 20px;
    font-size: 35px;
    text-align: left;
    font-weight: 700;
    background: black;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

.ProjectCardGroup {
    margin: 50px 40px 100px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    justify-items: center;
}

@media (max-width: 1060px) {
    .ProjectCardGroup {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 720px) {
    .ProjectCardGroup {
        grid-template-columns: repeat(1, 1fr);
    }
}