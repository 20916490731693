.text-and-video-container {
    display: flex;
    align-items: top;
    background-color: #000; /* Dark background color */
    color: #fff; /* Text color */
    width: 1248px;
    height: 400px;
  }
  
.text-content {
flex: 0.6;
padding-left: 50px;
padding-top: 50px;
}


.video-content-2{
  flex: 1.2;
  flex-grow: 1;
  margin-right: -32px;
}

.text-content-h4{
  font-weight: bold;
}

.text-content-p{
max-width: 75%;
}

.text-content-ul{
  max-width: 75%;
}
