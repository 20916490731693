.ImageCard{
    width: 200px;
    height: 125px;
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0 20px 40px rgba(0,0,0,0.25);
    display: grid;
    grid-template-rows: 1fr 1fr;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.ImageCard img {
    position: absolute;
    top: 0;
    height: 120%;
    z-index: -1;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.ImageCard canvas {
    position: absolute;
    top: 0;
    width: 150%;
    height: 150%;
    z-index: -1;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.ImageCard:hover canvas {
    transform: translateY(-20px);
}

.ImageCard h3{
    color: white;
    font-size: 30px;
    margin: 20px 0 0 20px;
    width: 190px;
}

.ImageCard p{
    color: rgba(255, 255, 255, 0.8);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
    align-self: end;
    margin: 0 0 20px 20px;
}

.ImageCard:hover{
    transform: scale(1.1, 1.1);
    box-shadow: 0 30px 60px rgba(0,0,0,0.5);
}

.ImageCard:hover img {
    transform: translateY(-20px);
}

/* @media (min-width: 992px){
    .my-modal-lg {
        width: auto;
    }
}


@media (min-width: 768px)
{
    .my-modal-dialog {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
     }
}

@media (min-width: 768px)
{
    .my-modal-content {
        box-shadow: 0 5px 15px rgba(0,0,0,.5);
        height: auto;
        min-height: 100%;
        border-radius: 0;
    }
} */

footer{
    background: none !important;
    box-shadow: none  !important;
    margin-top: 50px;
}
