.logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
.logo-img {
    float: left;
}
  
.logo-text {
font-size: 30px;
padding-left: 5px;
float: left;
}

