/* color: "#6B1ECF"; */



.service-tab-container {
    display: grid;
    gap: 40px;
    justify-content: center;
    width: 100%;
  }

  .stacked-tabs {
    display: flex;
    flex-direction: column;
  }

  .MuiTabs-flexContainer{
    gap: 27%;
    flex-wrap: wrap;
  }

  @media (max-width: 768px) {
    .MuiTabs-flexContainer{
      gap: 27%;
      flex-direction: column;
      display: block;
    }
  }

  .MuiTabs-scroller{
    margin-left: 40px;
  }

  .custom-tab{
    font-size: 18px !important;
    color: rgba(0,0,0,.7) !important;
    
    /* font-display: 300px !important; */
    font-weight: bold !important;
    font-family: "Lucida Console", "Courier New", monospace !important;
  }

  .MuiTabs-indicator {
    background-color: #6B1ECF !important; /* Change this to the desired color */
    height: 5px !important;
  }
  