.LabelSetCard{
    width: 250px;
    height: 200px;
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0 20px 40px rgba(0,0,0,0.25);
    display: grid;
    grid-template-rows: 1fr 1fr;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.LabelSetCard img {
    position: absolute;
    top: 0;
    height: 110%;
    z-index: -1;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.LabelSetCard canvas {
    position: absolute;
    top: 0;
    width: 150%;
    height: 150%;
    z-index: -1;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

/* .LabelSetCard:hover canvas {
    transform: translateY(-20px);
} */

.LabelSetCard h3{
    color: white;
    font-size: 25px;
    margin: 20px 0 0 20px;
    width: 190px;
}

.LabelSetCard p{
    color: rgba(255, 255, 255, 0.8);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
    align-self: end;
    margin: 0 0 20px 20px;
}

.LabelSetCard .DownloadIcon{
    color: white;
    font-size: 25px;
    margin: -64px 0 0 188px;
    width: 190px;
}

.DownloadIcon:hover{
    transform: scale(1.0, 1.1);
    box-shadow: 0 30px 60px rgba(0,0,0,0.5);
}

.DownloadIcon:hover img {
    transform: translateY(-20px);
}

