.leaflet-container {
  height: 100%;
  margin-right: 150px;
}

.custom-clip-loader {
  position: absolute;
  color: grey;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000; /* Ensure the spinner appears on top */
}

.custom-clip-loader p {
  color: black;
  font-size: 16px;
  font-weight: bolder;
}

.toolbox-right {
  position: fixed;
  top: 200px;
  right: 50px;
  width: 80px;
  height: 500px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(11, 11, 11, 0.1);
  border-radius: 8px;
  padding: 20px;
  z-index: 1000; /* Adjust as needed */
}

.toolbox-icon:hover{
  transform: scale(1.1, 1.1);
  box-shadow: 0 30px 60px rgba(0,0,0,0.5);
}